export const oldProjectsData = [
    {
      id: 1,
      title: "Territories",
      description: "A multi user web app that utilized players real time locations.",
      technologies: ["React", "CSS","Node.js", "Express", "MongoDB"],
      imageUrl: "imgs/territories.png",
      githubUrl: "https://github.com/mirasonicrex/Territories-client",
      date: "2021-01-12",
    },
    {
      id: 2,
      title: "Pokemon Team Builder",
      description: "A simple app to help you see what types your team is comprised of.",
      technologies: ["HTML", "CSS", "JavaScript"],
      imageUrl: "imgs/pokemon.png",
      githubUrl: "https://github.com/mirasonicrex/Pokemon-Team-Builder",
      date: "2020-10-01",
    },
    {
        id: 3,
        title: "Blog",
        description: "A blog application with the ability to signup, login, post, edit, delete, comment, and favorite.",
        technologies: ["Django", "Python", "PostreSQL"],
        imageUrl: "imgs/blog.png",
        githubUrl: "https://github.com/mirasonicrex/blog-python",
        date: "2021-03-16",
      },{
      id: 4,
      title: "3rd Person Shooter",
      description: "A 3rd to 1st person shooter controller made in Unity",
      technologies: ["Unity", "C#"],
      imageUrl: "imgs/UnityGame.png",
      githubUrl: "https://github.com/mirasonicrex/Unity-3rd-Person-Shooter-Game",
      date: "2020-03-01",
    },
    {
      id: 5,
      title: "Supply Chain Manager",
      description: "An automated supply chain solution that automatically dispatches orders upon payment.",
      technologies: ["Solidity", "React", "Truffle"],
      imageUrl: "imgs/SupplyChain.png",
      githubUrl: "https://github.com/mirasonicrex/SupplyChainManager",
      date: "2021-07-01",
    },
    {
        id: 6,
        title: "Mars Real Estate",
        description: "A mock real estate app that lets you post, update, and delete listings selling plots of land on Mars.",
        technologies: ["Node.js", "Express", "MongoDB"],
        imageUrl: "imgs/marsrealty.png",
        githubUrl: "https://github.com/username/project-two",
        date: "2020-12-01",
      },
  ];